<template>
  <b-row>
    <b-col lg="4" md="4" sm="6">
      <statistic-card-with-line-chart
          icon="VideoIcon"
          :statistic="$t('youtube.content')"
          :statistic-title="$t('youtube.duringlast')"
          :statisticText="`${this.$t('youtube.total')}: ${format(data.media_count.value)}`"
          :chart-data="[{name:$t('youtube.content'), data: seriesMedia}]"
          :color="'successs'"
          :chartOptions="chartOptions"
          :heightChart="130"
          v-if="data && data.media_count"
        />
    </b-col>
    <b-col lg="4" md="4" sm="6">
      <statistic-card-with-line-chart
          icon="EyeIcon"
          :statistic="$t('youtube.viewRate')"
          :statistic-title="$t('youtube.duringlast')"
          :statisticText="`${this.$t('youtube.total')}: ${format(data.views_avg.performance.all.value)}`"
          :chart-data="[{name: $t('youtube.content'), data: seriesViewsAvg}]"
          :color="'successs'"
          :chartOptions="chartOptions"
          :heightChart="130"
          v-if="data && data.views_avg"
        />
    </b-col>
    <b-col lg="4" md="4" sm="6">
      <statistic-card-with-line-chart
          icon="EyeIcon"
          :statistic="$t('youtube.contentRate')"
          :statistic-title="$t('youtube.duringlast')"
          :statisticText="`${this.$t('youtube.total')}: ${format(data.video_views_avg.performance.all.value)}`"
          :chart-data="[{name:$t('youtube.content'), data: seriesVideoAvg}]"
          :color="'successs'"
          :chartOptions="chartOptions"
          :heightChart="130"
        />
    </b-col>
    <b-col lg="6" md="6" sm="12">
      <b-card no-body>
        <b-card-header>
          <b-card-title>{{$t('youtube.viewRate')}}</b-card-title>
        </b-card-header>
        <b-card-body class="pb-0">
          <div class="d-flex justify-content-start mb-3">
            <div class="mr-2">
              <b-card-text class="mb-50">
                {{$t('youtube.views')}}
              </b-card-text>
              <h3 class="font-weight-bolder">
                <span class="text-primary">{{ format(data.views_count.value) }}</span>
              </h3>
            </div>
            <div>
              <b-card-text class="mb-50">
                {{$t('youtube.averageViews')}}
              </b-card-text>
              <h3 class="font-weight-bolder">
                <span>{{ format(data.views_avg.performance.all.value) }}</span>
              </h3>
            </div>
          </div>
          <vue-apex-charts
            type="line"
            height="240"
            :options="options"
            :series="[{name: $t('youtube.views'), data: seriesViews}, {name: $t('youtube.averageViews'), data: seriesViewsAvg}]"
          />
        </b-card-body>
      </b-card>
    </b-col>
    <b-col lg="6" md="6" sm="12">
      <b-card no-body>
        <b-card-header>
          <b-card-title> {{$t('youtube.commentsChannel')}}</b-card-title>
        </b-card-header>
        <b-card-body class="pb-0">
          <div class="d-flex justify-content-start mb-3">
            <div class="mr-2">
              <b-card-text class="mb-50">
                {{$t('youtube.comments')}}
              </b-card-text>
              <h3 class="font-weight-bolder">
                <span class="text-primary">{{ format(data.comments_count.performance.all.value) }}</span>
              </h3>
            </div>
            <div>
              <b-card-text class="mb-50">
                {{$t('youtube.averageComments')}}
              </b-card-text>
              <h3 class="font-weight-bolder">
                <span>{{ format(data.comments_avg.performance.all.value) }}</span>
              </h3>
            </div>
          </div>
          <vue-apex-charts
            type="line"
            height="240"
            :options="options"
            :series="[{name: $t('youtube.comments'), data: seriesComments}, {name: $t('youtube.averageComments'), data: seriesCommentsAvg}]"
          />
        </b-card-body>
      </b-card>
    </b-col>
    <b-col lg="12" md="12" sm="12">
      <b-card no-body>
        <b-card-header>
          <b-card-title>Likes/Dislikes</b-card-title>
        </b-card-header>
        <b-card-body class="pb-0">
          <div class="d-flex justify-content-start mb-3">
            <div class="mr-2">
              <b-card-text class="mb-50">
                Likes
              </b-card-text>
              <h3 class="font-weight-bolder">
                <span class="text-primary">{{ format(data.likes_count.performance.all.value) }}</span>
              </h3>
            </div>
            <div>
              <b-card-text class="mb-50">
                Dislikes
              </b-card-text>
              <h3 class="font-weight-bolder">
                <span>{{ format(data.dislikes_count.performance.all.value) }}</span>
              </h3>
            </div>
          </div>
          <vue-apex-charts
            type="line"
            height="240"
            :options="options"
            :series="[{name: 'Likes', data: seriesLikes}, {name: 'Dislikes', data: seriesDislikes}]"
          />
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
import {BRow, BCol, BCardTitle, BCardText, BCard, BCardBody, BCardHeader} from 'bootstrap-vue'
import StatisticCardWithLineChart from '@core/components/statistics-cards/StatisticCardWithLineChart.vue'
import utils from '@/libs/utils'
import VueApexCharts from 'vue-apexcharts'
export default {
  components: {
    BRow,
    BCol,
    BCardTitle,
    BCardText,
    BCard,
    BCardBody,
    BCardHeader,
    StatisticCardWithLineChart,
    VueApexCharts
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      chartOptions: {
        grid: {
          show: false,
          padding: {
            left: 30,
            right: 30,
            bottom: 30
          },
        },
        chart: {
          type: 'line',
          dropShadow: {
            enabled: true,
            top: 5,
            left: 0,
            blur: 4,
            opacity: 0.1,
          },
          toolbar: {
            show: false,
          },
          sparkline: {
            enabled: true,
          },
        },
        stroke: {
          width: 5,
          curve: 'smooth',
        },
        xaxis: {
          type: 'category',
          categories: ['30d', '90d', '180d'],
          show: true,
          labels: {
            style: {
              colors: '#b9b9c3',
              fontSize: '0.857rem',
            },
            show: true,
            offsetY: -5,

          }
        },
        colors: ['#A9A2F6'],
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            gradientToColors: ['#A9A2F6'],
            shadeIntensity: 1,
            type: 'horizontal',
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100, 100, 100],
          },
        },
        markers: {
          size: 0,
          hover: {
            size: 2,
          },
        },
        tooltip: {
          enabled: true,
          x: { show: true },
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          show:true,
          position: 'top'
        }
      },
      options: {
        chart: {
          toolbar: { show: false },
          zoom: { enabled: false },
          type: 'line',
          offsetX: -10,
        },
        stroke: {
          curve: 'smooth',
          dashArray: [0, 12],
          width: [4, 3],
        },
        legend: {
          show: false,
        },
        colors: ['#d0ccff', '#ebe9f1'],
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            inverseColors: false,
            gradientToColors: ['#d0ccff', '#ebe9f1'],
            shadeIntensity: 1,
            type: 'horizontal',
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100, 100, 100],
          },
        },
        markers: {
          size: 0,
          hover: {
            size: 5,
          },
        },
        xaxis: {
          labels: {
            style: {
              colors: '#b9b9c3',
              fontSize: '0.857rem',
            },
          },
          axisTicks: {
            show: false,
          },
          categories: ['30d', '90d', '180d'],
          axisBorder: {
            show: false,
          },
          tickPlacement: 'on',
        },
        yaxis: {
          tickAmount: 5,
          labels: {
            style: {
              colors: '#b9b9c3',
              fontSize: '0.857rem',
            },
            formatter(val) {
              return val > 999 ? `${(val / 1000).toFixed(0)}k` : val
            },
          },
        },
        grid: {
          borderColor: '#e7eef7',
          padding: {
            top: -20,
            bottom: -10,
            left: 20,
          },
        },
        tooltip: {
          x: { show: false },
        },
      }
    }
  },
  computed: {
    seriesMedia() {
      return utils.getSeries(this.quitAll(this.data.media_count.performance),'value')
    },
    seriesViewsAvg() {
      return utils.getSeries(this.quitAll(this.data.views_avg.performance),'value')
    },
    seriesViews() {
      return utils.getSeries(this.quitAll(this.data.views_count.performance),'value')
    },
    seriesVideoAvg() {
      return utils.getSeries(this.quitAll(this.data.video_views_avg.performance),'value')
    },
    seriesComments() {
      return utils.getSeries(this.quitAll(this.data.comments_count.performance),'value')
    },
    seriesCommentsAvg() {
      return utils.getSeries(this.quitAll(this.data.comments_avg.performance),'value')
    },
    seriesLikes() {
      return utils.getSeries(this.quitAll(this.data.likes_count.performance),'value')
    },
    seriesDislikes() {
      return utils.getSeries(this.quitAll(this.data.dislikes_count.performance),'value')
    }
  },
  methods: {
    quitAll(data) {
      const arr = utils.convertData(data)
      arr.pop()
      return arr
    },
    format(num) {
      return utils.getFormat(num)
    }
  }
}
</script>
